import classes from './index.module.css'

export default function Footer() {
	return (
		<div className={`${classes.footer} ${classes.footerBg}`}>
			<p className="text-center text-white mb-0">
				Ant Design ©2018 Created by Ant UED
			</p>
		</div>
	)
}
