import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import styles from "./acceptTermsModal.module.less";
import { Button, Modal, Checkbox, Input, Skeleton, Form, Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const AcceptTermsModal = (props) => {
  const { visible, handleRequest, handleCancel, listing } = props;
  const [checked, setChecked] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const onChange = (e: CheckboxChangeEvent) => {
    setChecked(!e.target.checked);
  };

  return (

    <Modal
      visible={visible}
      title="Terms and Conditions"
      onCancel={handleCancel}
      footer={[
        <Button disabled={checked} key="submit" type="primary" onClick={handleRequest}>
          Accept
        </Button>,
      ]}
    >
      {
        <>
        <Form>
          <h3>Accept Terms and Conditions:</h3>
          <a href="/terms" target="_blank" rel="noopener noreferrer">
            I accept the terms and conditions:
          </a>
          <div>
            <Form.Item
            rules={[{required:true}]}
            >
            <Checkbox
            onChange={onChange}
            ></Checkbox>
            </Form.Item>

          </div>
          </Form>
        </>
      }
    </Modal>
  );
};

AcceptTermsModal.propTypes = {};

AcceptTermsModal.defaultProps = {};

export default AcceptTermsModal;
