import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { GoogleLogin } from 'react-google-login'
import { gapi } from 'gapi-script';

import { Input, Checkbox, Button } from 'antd';
import { UserOutlined, LockOutlined, FacebookOutlined, GooglePlusOutlined, TwitterSquareFilled } from '@ant-design/icons';

import useInput from "hooks/use-input";
import setAuthToken from "utils/setAuthToken";
import { loginUser } from "apis/auth";

import "assets/css/auth.css";

const isNotEmpty = (value) => value.trim() !== "";

const Login = () => {

	let history = useHistory();

	const {
		value: userIdValue,
		hasError: userIdHasError,
		valueChangeHandler: userIdChangeHandler,
		inputBlurHandler: userIdBlurHandler,
		reset: resetuserId,
	} = useInput(isNotEmpty);

	const {
		value: passwordValue,
		valueChangeHandler: passwordChangeHandler,
		inputBlurHandler: passwordBlurHandler,
		reset: resetPassword,
	} = useInput(isNotEmpty);

	const submitHandler = (event) => {
		event.preventDefault();
		const formData = {
			user_id: userIdValue,
			password: passwordValue,
		};
		loginUser(formData)
			.then(async (res) => {
				const body = await res.json();
				const statusCode = res.status;
				console.log(body);
				console.log(res.status);
				const note = body.message

				toast.info(note)
				const userId = body.userId;
				localStorage.setItem("token", userId);
				if (statusCode === 200) {
					setAuthToken(userId);
					localStorage.setItem("token", userId);
					history.push("/search");
				}
			})
			.catch((error) => console.log(error));
		resetuserId();
		resetPassword();
	};

	const handleLogin = (res) => {
		
		const postBody = {
			user_id: res.profileObj.email,
      first_name: res.profileObj.givenName,
      last_name: res.profileObj.familyName,
      email: res.profileObj.email,
      phone: "13412341234",
      latitude:  "23",
      longitude: "235",
      address: "1223 new cres L5V2T4"
		}

		axios.post('https://f819d9beh2.execute-api.us-west-2.amazonaws.com/prod/user', postBody).then((res) => {
			console.log(res)
			if (res.status === 200) {
				history.push({
          pathname: '/passwordPut',
          state: res.data.userId
        });
			}
		}).catch(() => {
			localStorage.setItem("token", res.profileObj.email)
			history.push("/listing")
		})
	}

	const handleFailure = (res) => {
		console.log(res)
	}

	useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);

	return (
		<div className="auth-bg">
			<div className="container" id="signIn-container">
				<div className="signUp-body h-100" id="signIn">
					<div className="card bg-white">
						<div className="card-header bg-white">
							<h3 className='text-dark mb-0'>Sign In</h3>
							<div className="d-flex justify-content-end social_icon">
								<FacebookOutlined />
								<GooglePlusOutlined />
								<TwitterSquareFilled />
							</div>
						</div>
						<div className="card-body">
							<form onSubmit={submitHandler}>
								<div className="input-group form-group">
									<div className="d-flex justify-content-center">
										<Input
											type="text"
											size='large'
											prefix={<UserOutlined />}
											id="name"
											placeholder="userId"
											value={userIdValue}
											onChange={userIdChangeHandler}
											onBlur={userIdBlurHandler}
											status={userIdHasError ? 'error' : ''}
										/>
									</div>
								</div>
								<div className="input-group form-group">
									<div className="d-flex justify-content-center">
										<Input
											type="password"
											size='large'
											prefix={<LockOutlined />}
											id="password"
											placeholder="Password"
											value={passwordValue}
											onChange={passwordChangeHandler}
											onBlur={passwordBlurHandler}
											status={userIdHasError ? 'error' : ''}
										/>
									</div>
								</div>
								<div className="remember d-flex justify-content-between">
									<Checkbox className='text-dark'>Remember me</Checkbox>
									<a className="login-form-forgot text-skyblue" href="">
										Forgot password
									</a>
								</div>
								<div className="form-group-btn">
									<Button type='primary' size='large' onClick={submitHandler} className='w-100 mt-4 bg-skyblue border-0'>
										Submit
									</Button>
								</div>
								<div className='mb-4 google-login'>
									<GoogleLogin
										clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}								
										buttonText="Login with Google"
										onSuccess={handleLogin}
										onFailure={handleFailure}
										cookiePolicy={'single_host_origin'}
									/>
								</div>
								<div className="d-flex justify-content-center links text-dark">
									Don't have an account?
									<a
										href="/register"
										id="signUp-btn"
										className='text-skyblue'
										onClick={(e) => {
											e.preventDefault();
											history.push('/register')
										}}
									>
										Sign Up
									</a>
								</div>
							</form>
						</div>
					</div>
					<ToastContainer />
				</div>
			</div>
		</div>
	);
};

export default Login;
