import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import countryList from "react-select-country-list";
import ReactGA from "react-ga4";
import { Input, Button, Select, Form } from "antd";
import {
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  HomeOutlined,
} from "@ant-design/icons";

import { registerUserAxios } from "apis/auth";

import "assets/css/auth.css";

const Register = () => {
  let history = useHistory();
  const [form] = Form.useForm();

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [country, setCountry] = useState("CA");
  const [formData, setFormData] = useState({});

  const countries = useMemo(() => countryList().getData(), []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [isError, setIsError] = useState("");
  const [notification, setNotify] = useState("");

  const submitHandler = async (event) => {
    //event.preventDefault();
    ReactGA.event({
      category:"User",
      action: "UserSignUp"
    })
    const newformData = {
      ...event,
      latitude: latitude,
      longitude: longitude,
      country: country,
    };
    console.log(newformData);
    try {
      let res = await registerUserAxios(newformData);
      const note = res.data.message;
      setNotify(note);
      if (res.status === 200) {
        history.push({
          pathname: "/passwordPut",
          state: newformData.userId,
        });
      }
    } catch (error) {
      console.log("err", error);
      setIsError(error);
    }
  };

  useEffect(() => {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (geo) => {
          setLatitude(geo.coords.latitude);
          setLongitude(geo.coords.longitude);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, []);

  return (
    <div className="auth-bg">
      <div className="container" id="signUp-container">
        <div className="signUp-body">
          <div className="card bg-white">
            <div className="card-header bg-white">
              <h3 className="text-dark text-center mb-0">Sign Up</h3>
            </div>
            <div className="card-body">
              <Form
              form={form}
              onFinish={submitHandler}
              scrollToFirstError
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your username",
                    }
                  ]}
                  name="userId"
                >
                  <Input
                    value={formData.userId}
                    onChange={handleChange}
                    placeholder="User Id"
                    prefix={<UserOutlined />}
                    type="text"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your firstname",
                    }
                  ]}
                  name="firstName"
                >
                  <Input
                    placeholder="First Name"
                    prefix={<UserOutlined />}
                    type="text"
                    size="large"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your lastName",
                    }
                  ]}
                  name="lastName"
                >
                  <Input
                    placeholder="Last Name"
                    prefix={<UserOutlined />}
                    type="text"
                    size="large"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your email",
                    },
                    {type: "email", message:'Please enter valid email'}
                  ]}
                  name="email"
                >
                  <Input
                    placeholder="Email"
                    prefix={<MailOutlined />}
                    type="text"
                    size="large"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your phoneNumber",
                    }
                  ]}
                  name="phoneNumber"
                >
                  <Input
                    placeholder="Phone Number"
                    prefix={<PhoneOutlined />}
                    type="text"
                    size="large"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your postal code",
                    }
                  ]}
                  name="postalCode"
                >
                  <Input
                    placeholder="Postal Code"
                    prefix={<UserOutlined />}
                    type="text"
                    size="large"
                    value={formData.postalCode}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your state",
                    },
                  ]}
                  name="state"
                >
                  <Input
                    placeholder="State"
                    prefix={<UserOutlined />}
                    type="text"
                    size="large"
                    value={formData.state}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your country",
                    },
                  ]}
                >
                  <Select
                    name="country"
                    size="large"
                    value={country}
                    onChange={(val) => setCountry(val)}
                    placeholder="Country"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {countries.map((item) => (
                      <Select.Option value={item.value} key={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your address",
                    },
                  ]}
                  name="address"
                >
                  <Input
                    type="text"
                    size="large"
                    prefix={<HomeOutlined />}
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your city",
                    },
                  ]}
                  name="city"
                >
                  <Input
                    type="text"
                    size="large"
                    prefix={<HomeOutlined />}
                    placeholder="City"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    className="w-100 border-0 bg-skyblue"
                  >
                    Register
                  </Button>
                </Form.Item>
              </Form>

            </div>
          </div>
          {notification && <ToastContainer />}
          <div className="d-flex justify-content-center clear">
            {isError && (
              <div
                className="password-val"
                style={{ backgroundcolor: "Tomato" }}
              >
                {" "}
                {notification}{" "}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
