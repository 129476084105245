import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import { Spin } from 'antd';

const StripeToken = () => {

  const history = useHistory()
  const useQuery = () => new URLSearchParams(useLocation().search);

  const query = useQuery() 

  useEffect(() => {
    
    const postBody = {
      action: "verify",
      user_id: localStorage.getItem('token'),
      code: query.get('code')
    }

    axios.post(`${process.env.REACT_APP_PROXY_PATH}/listing/stripe`, postBody).then((res) => {      
      toast.success("Success")
      setTimeout(() => {
        history.push('/portal')  
      }, 2000);      
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  return (
    <div className='stipe-token-page d-flex'>
      <Spin size="large" />
      <ToastContainer />
    </div>
  )
}

export default StripeToken
