import React, { useEffect } from 'react'
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';

const Canceled = () => {

  const history = useHistory()

  useEffect(() => {
    notification.error({
      message: 'Transaction Canceled!',
      description: 'Transaction is canceled!',
      onClose: () => {
        history.push('/search')
      }
    })
  }, [])

  return (
    <div>

    </div>
  )
}

export default Canceled
