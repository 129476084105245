import React, { useState, useMemo } from 'react';
import axios from 'axios'
import countryList from 'react-select-country-list'
import { Input, Button, Select, Form } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import ReactGA from "react-ga4";
import "assets/css/auth.css";

const SellerSignup = () => {

  const countries = useMemo(() => countryList().getData(), [])

  const [country, setCountry] = useState('CA')
  const [accountType, setAccountType] = useState("individual");
  const [formData, setFormData] = useState({})
  
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const submitHandler = (e) => {
    //e.preventDefault()
    let postbody = {
      ...formData,
      type: accountType,
      country: country
    }
    console.log(postbody);
    
    axios.post(`${process.env.REACT_APP_PROXY_PATH}/listing/stripe`, postbody).then((res) => {
      console.log(res)
      ReactGA.event({  category: 'User',
      action: 'UserSignUp'});
      window.location.replace(res.data.url, '_self');
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <div className="auth-bg">
      <div className="container" id="signUp-container">
        <div className="signUp-body">
          <div className="card bg-white">
            <div className="card-header bg-white py-4">
              <h3 className="text-dark text-center mb-0">
                Create your account
              </h3>
            </div>
            <div className="card-body">
              <Form onFinish={submitHandler} scrollToFirstError>
                  <Form.Item>
                    <Select
                      name="metrics"
                      size="large"
                      value={accountType}
                      onChange={(val) => setAccountType(val)}
                      placeholder="Type"
                    >
                      <Select.Option value="individual">
                        Individual
                      </Select.Option>
                      <Select.Option value="business">Business</Select.Option>
                    </Select>
                  </Form.Item>
                {accountType === "business" && (
                      <Form.Item 
                      name="businessName"
                      rules={[
                        {
                          required: true,
                          message: "Please input business name",
                        }
                      ]}
                      >
                        <Input
                          type="text"
                          size="large"
                          prefix={<UserOutlined />}
                          className="form-control"
                          id="businessName"
                          placeholder="Business Name"
                          value={formData.businessName}
                          onChange={handleChange}
                        />
                      </Form.Item>
                )}

                    <Form.Item
                     name="firstName"
                     rules={[
                      {
                        required: true,
                        message: "Please input your firstname",
                      }
                    ]}
                    >
                      <Input
                        type="text"
                        size="large"
                        prefix={<UserOutlined />}
                        className="form-control"
                        id="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </Form.Item>

                    <Form.Item
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your lastName",
                          }
                        ]}                                            
                    >
                      <Input
                        type="text"
                        size="large"
                        prefix={<UserOutlined />}
                        className="form-control"
                        id="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </Form.Item>

                    <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email",
                      },
                      {type: "email", message:'Please enter valid email'}
                    ]}       
             
                    >
                      <Input
                        type="text"
                        size="large"
                        prefix={<UserOutlined />}
                        id="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </Form.Item>

                    <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Please input your phoneNumber",
                          }
                        ]}
                    >
                      <Input
                        type="text"
                        size="large"
                        prefix={<UserOutlined />}
                        className="form-control"
                        id="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </Form.Item>

                <div className="mb-10-px">
                  <Form.Item name="country" rules={[{required: true, message: "Please select your country"}]}>
                    <Select
                      showSearch
                      size="large"
                      value={country}
                      onChange={(val) => setCountry(val)}
                      placeholder="Country"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {countries.map((item) => (
                        <Select.Option value={item.value} key={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        className="w-100 border-0 bg-skyblue"
                      >
                        Register
                      </Button>
                    </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellerSignup
