import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Input, Select, Typography, Button, notification } from 'antd';
import { AddressAutofill } from '@mapbox/search-js-react';

import useInput from "hooks/use-input";
import { createListing } from "apis/post";

import MapBox from 'components/MapBox'
import ImagesUpload from 'components/ImagesUpload'

import "assets/css/listing.css";

const isNotEmpty = (value) => value.trim() !== "";

const AddListing = () => {

  const history = useHistory();

  const { TextArea } = Input;
  const { Option } = Select;
  const { Title } = Typography;

  const [ownerId, setOwnerId] = useState("");
  const [addressLine, setAddressLine] = useState("")
  const [geoLocation, setGeoLocation] = useState()

  useEffect(() => {
    const userId = localStorage.getItem('token');
    setOwnerId(userId);
  }, []);

  const {
    value: listing_nameValue,
    valueChangeHandler: listing_nameChangeHandler,
    inputBlurHandler: listing_nameBlurHandler,
    reset: resetlisting_name,
  } = useInput(isNotEmpty);

  const {
    value: lengthValue,
    valueChangeHandler: lengthChangeHandler,
    inputBlurHandler: lengthBlurHandler,
    reset: resetLength,
  } = useInput(isNotEmpty);

  const {
    value: breadthValue,
    valueChangeHandler: breadthChangeHandler,
    inputBlurHandler: breadthBlurHandler,
    reset: resetBreadh,
  } = useInput(isNotEmpty);

  const {
    value: heightValue,
    valueChangeHandler: heightChangeHandler,
    inputBlurHandler: heightBlurHandler,
    reset: resetHeight,
  } = useInput(isNotEmpty);

  const {
    valueChangeHandler: ownerChangeHandler,
    inputBlurHandler: ownerBlurHandler,
    reset: resetowner,
  } = useInput(isNotEmpty);

  const {
    value: max_heightValue,
    valueChangeHandler: max_heightChangeHandler,
    inputBlurHandler: max_heightBlurHandler,
    reset: resetmax_height,
  } = useInput(isNotEmpty);

  const {
    value: descriptionValue,
    valueChangeHandler: descriptionChangeHandler,
    inputBlurHandler: descriptionBlurHandler,
    reset: resetdescription,
  } = useInput(isNotEmpty);

  const {
    value: priceValue,
    valueChangeHandler: priceChangeHandler,
    inputBlurHandler: priceBlurHandler,
    reset: resetprice,
  } = useInput(isNotEmpty);

  const area = lengthValue * breadthValue * heightValue

  const [image, setFile] = useState([]);

  const [metricsValue, setMetrics] = useState("imperial");

  function handleMetricsChange(value) {
    setMetrics(value);
  }

  const [isError, setIsError] = useState("")

  const [toastNotification, setNotify] = useState("")

  const submitHandler = (event) => {
    event.preventDefault();

    if (!geoLocation) {
      notification.error({
        message: 'Invalid location',
        description:
          'Location is mandatory.'
      });
    } else {
      const formData = {
        listing_name: listing_nameValue,
        metadata: {
          images: image.map((img) => img.fileName),
          description: descriptionValue,
          length: lengthValue,
          breadth: breadthValue,
          height: heightValue
        },
        listing_address: addressLine,
        active: true,
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude,
        metrics: metricsValue,
        owner: ownerId,
        max_height: max_heightValue,
        space_area: area,
        price: priceValue
      };
      console.log("formData", formData);

      createListing(formData)
        .then((res) => {
          history.push("/listing");
          const err = res.body;

          setIsError(err);
          setNotify(res.body);
          resetlisting_name();
          resetdescription();
          resetBreadh();
          resetLength();
          resetHeight();
          resetowner();
          resetmax_height();
          resetprice();
        })
        .catch((error) => console.log(error));
    }
  }

  const handleRetrieve = useCallback((res) => {   
    setGeoLocation({
      latitude: res.features[0].geometry.coordinates[1],
      longitude: res.features[0].geometry.coordinates[0]
    })
  }, []);

  return (
    <div className="listing-bg bg-white">
      <Title className="listing-title">Create Listing</Title>
      <form onSubmit={submitHandler}>
        <div className="d-flex flex-row">
          <div className="d-flex flex-column" style={{ width: "65%" }}>
            <Input
              type="text"
              size="large"
              style={{ marginBottom: 10 }}
              id="userId"
              placeholder="Listing Name"
              value={listing_nameValue}
              onChange={listing_nameChangeHandler}
              onBlur={listing_nameBlurHandler}
            />
            <AddressAutofill
              accessToken={process.env.REACT_APP_GOOGLE_MAP_ACCESS_TOKEN}
              onRetrieve={handleRetrieve}
            >
              <Input
                type="text"
                size="large"
                id="address"
                value={addressLine}
                onChange={(e) => {
                  setAddressLine(e.target.value)
                }}
                autoComplete="shipping address-line1"
                placeholder="Listing Address(populate the map to the left)"
              />
            </AddressAutofill>

            <TextArea
              id="userId"
              placeholder="Description"
              cols="10"
              rows="8"
              value={descriptionValue}
              onChange={descriptionChangeHandler}
              style={{ marginTop: 10 }}
              onBlur={descriptionBlurHandler}
            />
          </div>
          <div className="map-box ml-2">
            <MapBox
              geoLocation={geoLocation}
              setGeoLocation={(val) => setGeoLocation(val)}
            />
          </div>
        </div>

        <div className="metrics-select mb-10-px">
          <div className="field-title">Price Monthly</div>
          <Input
            type="number"
            size="large"
            className="form-control"
            id="monhtlyPrice"
            placeholder="Monthly Price"
            value={priceValue}
            onChange={priceChangeHandler}
            onBlur={priceBlurHandler}
          />
        </div>
        <div className="metrics-select mb-10-px">
          <label className="cars" htmlFor="cars">Calculate Area In</label>
          <Select
            name="metrics"
            size="large"
            className="form-control"
            value={metricsValue}
            onChange={handleMetricsChange}
            placeholder=""
          >
            <Option value="imperial">m(Imperial)</Option>
            <Option value="metric">ft(Metric)</Option>
          </Select>
        </div>

        <div className="d-flex flex-row" style={{ margintop: 20 }}>
          <div className="d-flex flex-column" style={{ width: "55%" }}>
            <div className="mb-10-px">
              <div className="field-title">Enter Lenth</div>
              <Input
                type="text"
                size="large"
                className="form-control"
                id="lenth"
                placeholder="Lenth"
                value={lengthValue}
                onChange={lengthChangeHandler}
                onBlur={lengthBlurHandler}
              />
            </div>
            <div className="mb-10-px">
              <div className="field-title">Enter Breadth</div>
              <Input
                type="text"
                size="large"
                className="form-control"
                id="breadth"
                placeholder="Breadth"
                value={breadthValue}
                onChange={breadthChangeHandler}
                onBlur={breadthBlurHandler}
              />
            </div>
            <div className="mb-10-px">
              <div className="field-title">Enter Height</div>
              <Input
                type="text"
                size="large"
                className="form-control"
                id="height"
                placeholder="Height"
                value={heightValue}
                onChange={heightChangeHandler}
                onBlur={heightBlurHandler}
              />
            </div>
            <div className="mb-10-px">
              <div className="field-title">Max Ceiling Height</div>
              <Input
                type="text"
                size="large"
                className="form-control"
                id="ceilingHeight"
                placeholder="Max Ceiling Height"
                value={max_heightValue}
                onChange={max_heightChangeHandler}
                onBlur={max_heightBlurHandler}
              />
            </div>

            <div className="mb-10-px">
              <div className="field-title">Owner</div>
              <Input
                type="text"
                size="large"
                className="form-control"
                id="owner"
                placeholder="Owner"
                value={ownerId}
                onChange={ownerChangeHandler}
                onBlur={ownerBlurHandler}
              />
            </div>
          </div>
          <div className="totalArea border">
            <div className="areaText">Total Area : {area} </div>
          </div>
        </div>
        <div className="d-flex flex-column" style={{ margintop: 20 }}>
          <p className="field-title">Upload Listing Images : </p>
          <ImagesUpload
            setFileList={(fileList) => setFile(fileList)}
            fileList={image}
          />
        </div>
        <div className="form-group-btn listing-craete-btn">
          <Button type='primary' size='large' onClick={submitHandler} shape="round" className='ml-2'>
            Create
          </Button>
        </div>
      </form>
      {toastNotification && <ToastContainer />}
      <div className="d-flex justify-content-center clear" >
        {isError && <div className='password-val' style={{ backgroundcolor: "Tomato" }}> {isError} </div>}
      </div>
    </div>
  );
};

export default AddListing;
