import React, { useState, useEffect } from 'react';
import axios from 'axios'

import { Input, Button, notification } from 'antd';

import "assets/css/listing.css";

const Profile = () => {

  const [profile, setProfile] = useState({})
  const [updatedData, setUpdatedData] = useState({})
  const [isEdit, setIsEdit] = useState(false)

  const handleChange = (e) => {
    setUpdatedData({
      ...updatedData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = () => {
    axios.put(`${process.env.REACT_APP_PROXY_PATH}/user`, updatedData).then((res) => {
      notification.success({
        message: 'Success',
        description: res.data.message
      });
      setProfile(updatedData)
      setIsEdit(false)
    }).catch((err) => {
      console.log(err)
      notification.error({
        message: 'Error',
        description: "Failed to update user"
      });
    })
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_PROXY_PATH}/user?id=${localStorage.getItem('token')}`).then((res) => {
      
      const profileData = {
        user_id: localStorage.getItem('token'),
        address_str: res.data.address_str,
        email: res.data.email,
        first_name: res.data.first_name,
        last_name: res.data.last_name,
        latitude: res.data.latitude,
        longitude: res.data.longitude,
        phone: res.data.phone
      }
      setProfile(profileData)
      setUpdatedData(profileData)
    }).catch(() => {
      setProfile({})
    })
  }, [])

  return (
    <div className='profile-wrapper'>
      <div className="container">
        <div className="col-12 col-md-8 offset-md-2">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-10-px">
                <div className="field-title">First Name</div>
                <Input
                  type="text"
                  size="large"
                  name='first_name'
                  className="form-control"
                  placeholder="First Name"
                  onChange={handleChange}
                  value={isEdit ? updatedData.first_name : profile.first_name ?? ''}
                  readOnly={!isEdit}
                />
              </div>

              <div className="col-12 mb-10-px">
                <div className="field-title">Last Name</div>
                <Input
                  type="text"
                  size="large"
                  name='last_name'
                  className="form-control"
                  placeholder="Last Name"
                  onChange={handleChange}
                  value={isEdit ? updatedData.last_name : profile.last_name ?? ''}
                  readOnly={!isEdit}
                />
              </div>

              <div className="col-12 mb-10-px">
                <div className="field-title">Email</div>
                <Input
                  type="text"
                  size="large"
                  name='email'
                  className="form-control"
                  placeholder="Email"
                  onChange={handleChange}
                  value={isEdit ? updatedData.email : profile.email ?? ''}
                  readOnly={!isEdit}
                />
              </div>

              <div className="col-12 mb-10-px">
                <div className="field-title">Phone</div>
                <Input
                  type="text"
                  size="large"
                  name='phone'
                  className="form-control"
                  placeholder="Phone"
                  onChange={handleChange}
                  value={isEdit ? updatedData.phone : profile.phone ?? ''}
                  readOnly={!isEdit}
                />
              </div>


              <div className="col-12 mb-10-px">
                <div className="field-title">Address</div>
                <Input
                  type="text"
                  size="large"
                  name='address_str'
                  className="form-control"
                  placeholder="Address"
                  onChange={handleChange}
                  value={isEdit ? updatedData.address_str : profile.address_str ?? ''}
                  readOnly={!isEdit}
                />
              </div>

              {
                isEdit ? (
                  <>
                    <div className="col-12 col-md-6">
                      <Button 
                        type='primary' 
                        onClick={() => {
                          setUpdatedData(profile)
                          setIsEdit(false)
                        }} 
                        size='large' 
                        className='w-100 border-0 bg-skyblue'
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-12 col-md-6">
                      <Button type='primary' onClick={handleSubmit} size='large' className='w-100 border-0 bg-skyblue'>
                        Update
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="col-12">
                    <Button type='primary' onClick={() => setIsEdit(true)} size='large' className='w-100 border-0 bg-skyblue'>
                      Edit
                    </Button>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
