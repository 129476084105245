import axios from "axios";


const createListing = (formData, history) => {
  console.log("action-formData", formData);
  return fetch(`${process.env.REACT_APP_PROXY_PATH}/listing`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...formData,
    }),
  })
  .then( async (res) => {
    const data = await res.json();
    console.log(data)
    return data;
  })
};

const editListings = (formData, history) => {
  return fetch(`${process.env.REACT_APP_PROXY_PATH}/listing`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...formData,
    }),
  })
  .then( async (res) => {
    const data = await res.json();
    console.log(data)
    return data;
  })
};

const editListingsAxios = async (formData) => {
  let response = await axios.put(`${process.env.REACT_APP_PROXY_PATH}/listing`, {
    ...formData
  });
return response;
};

const uploadImage = async (file, url) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', file.name);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  try{
    let response = await axios.post(`${process.env.REACT_APP_PROXY_PATH}/files?fileName=${url}.jpg`, formData, config);
    return response;
  } catch(error){
    console.log(error);
    console.log(`unable to make a call to S3 bucket`);
  }
};

export { createListing, editListings, editListingsAxios , uploadImage };
