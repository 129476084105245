
import classNamees from './americanExpress.module.css';
import './landingpage.css';
import './vendor/bootstrap/css/bootstrap.min.css';
import './vendor/bootstrap-icons/bootstrap-icons.css';
import './vendor/boxicons/css/boxicons.min.css';
import './vendor/glightbox/css/glightbox.min.css';
import './vendor/swiper/swiper-bundle.min.css';
import heroImage from './img/storageLocker.jpg'; // relative path to image 

const americanExpressContents = [
	{ time1: "PRESALE: THU-NOV-9PM", time2: "SUN.MAR.8:00 PM", title: "Elton Jonhn: Farewell Yellow Brick Road", text: "Lincoln, NE.Pinnacle Bank Arena", url: "/images/Homepage1/a45e91db-7b70-4a1b-a368-03f678a4e99f.jpg" },
	{ time1: "PRESALE: THU-DEC-7PM", time2: "WED.APR 27.6:30 PM", title: "HAIM One More HAIM Tour", text: "Berkeley, CA.Greek Theatre-U.C.Berkeley", url: "/images/Homepage1/91b30f59-a973-4975-991b-f9773202f76e.jpg" },
	{ time1: "PRESALE: THU-NOV-9PM", time2: "SUN.MAR 28.8:00 PM", title: "Elton Jonhn: Farewell Yellow Brick Road", text: "Lincoln, NE.Pinnacle Bank Arena", url: "/images/Homepage1/8cff9c16-e41c-4c44-9e0f-5708e4142bff.jpg" },
]

export default function AmericanExpress() {
	return (
		<>
  <section id="hero" className="d-flex align-items-center">

    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1>Rent a storage space near you!</h1>
          <h2>Browse through listings near you to book a space for renting, book and drop off your items the same day!</h2>
          <div><a href="/register" className="btn-get-started scrollto">Get Started</a></div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img">
          <img src={heroImage} className="img-fluid" alt=""></img>
        </div>
      </div>
    </div>

  </section>

  <main id="main">

    <section id="about" className="about">
      <div className="container">

        <div className="row">


          <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
            <h3>At Cubixpace we offer more than rental service</h3>
            <p>We offer robust solution for businesses to manage their inventory, you can book wearhouse space, commercial space, closer to consumers, and in city centers.</p>

            <div className="icon-box">
              <div className="icon"><i className="bx bx-fingerprint"></i></div>
              <h4 className="title"><a href="">Business Solution</a></h4>
              <p className="description">We offer businesses space/storage solution that has not been accessed before</p>
            </div>

            <div className="icon-box">
              <div className="icon"><i className="bx bx-gift"></i></div>
              <h4 className="title"><a href="">Ecommmerce/Drop Shipping</a></h4>
              <p className="description">Businesses/Stores that do not have space to store their inventory, can use our space as a platform for quick shipping and pick-up</p>
            </div>

            <div className="icon-box">
              <div className="icon"><i className="bx bx-atom"></i></div>
              <h4 className="title"><a href="">Pre Demand Stock-Up</a></h4>
              <p className="description">We help buinesses who expect seasonal demamd to stock up inventory near the targetted customers</p>
            </div>

          </div>
          <div className="col-xl-5 col-lg-6 d-flex justify-content-center video-box align-items-stretch position-relative">
          </div>
        </div>

      </div>
    </section>

    <section id="services" className="services section-bg">
      <div className="container">

        <div className="section-title">
          <h2>Cubixpace Platform Services</h2>
          <p>Cubixpace platform provide ability to rent out and rent spaces</p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-cash-stack" style={{color: "#ff689b"}}></i></div>
              <h4 className="title"><a href="">Rent out your space</a></h4>
              <p className="description">Renting your existing space that is unused can you help you generate much needed cash flow. Or you can better full fill your active exsiting spaces.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-calendar4-week" style={{color: "#e9bf06"}}></i></div>
              <h4 className="title"><a href="">Quick Storage</a></h4>
              <p className="description">If you have immediate storage needs look no further we have mutiple listings online that can help with your storage needs.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-wow-delay="0.1s">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-chat-text" style={{color: "#3fcdc7"}}></i></div>
              <h4 className="title"><a href="">Retail Buinessess</a></h4>
              <p className="description">If your business reqires to decrease shipping time to customer and yeild, we can help you store inventory closer to the customer hotspots.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" data-wow-delay="0.1s">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-credit-card-2-front" style={{color:"#41cf2e"}}></i></div>
              <h4 className="title"><a href="">Partner with Us</a></h4>
              <p className="description">Partner with us to add your storage space for better visibility and management</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-wow-delay="0.2s">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-globe" style={{color: "#d6ff22"}}></i></div>
              <h4 className="title"><a href="">Security and Visibility</a></h4>
              <p className="description">We ensure all partners have gone through sufficient vetting in order to partner with us</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" data-wow-delay="0.2s">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-clock" style={{color: "#4680ff"}}></i></div>
              <h4 className="title"><a href="">Schedule the Rental Time</a></h4>
              <p className="description">We create the contracts according to the time and data for start and end of the contract.</p>
            </div>
          </div>
        </div>

      </div>
    </section>


  </main>
</>

	)
}
