import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios';

import { Input, Button, Form, Spin, Radio, Slider, Space, Select, notification } from 'antd';
import { AddressAutofill } from '@mapbox/search-js-react';
import ListingCard from './components/ListingCard'
import MapBox from 'components/MapBox'
import ListingModal from 'components/ListingModal'
import ReactGA from "react-ga4";
import 'assets/css/search.css'
import AcceptTermsModal from 'components/acceptTermsModal/acceptTermsModal';
const Search = () => {

  const [listings, setListings] = useState([])
  const [searchKeys, setSearchKeys] = useState({
    nearByResultsLimit: 5
  })
  const [isLoading, setIsLoading] = useState(false)
  const [geoLocation, setGeoLocation] = useState()
  const [unit, setUnit] = useState('imperial')
  const [selectedId, setSelectedId] = useState('')
  const [addressLine, setAddressLine] = useState("")
  const [openDialog, setOpenDialog] = useState(false)
  const [acceptTermDialog, setacceptTermDialog] = useState(false)
  const [showSpin, setShowSpin] = useState(false)
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [termsCondition, settermsCondition] = useState(false);

  const handleChange = (e) => {
    setSearchKeys({
      ...searchKeys,
      [e.target.name]: e.target.value
    })
  }

  const handleSearch = (val) => {
    setIsLoading(true)
    if (geoLocation || val == "internal") {
      const postBody = {
        ...searchKeys,
        units: unit
      };
      if(val === "internal"){
        console.log("inside internal")
        if (window.navigator.geolocation) {
          window.navigator.geolocation.getCurrentPosition((geo) => {
            ReactGA.event({  category: 'Search',
            action: 'UserSearchSubmit'});
            setLatitude(geo.coords.latitude);
            setLongitude(geo.coords.longitude);
            console.log(geo.coords.latitude)
            console.log(geo.coords.longitude)
            postBody.latitude =  geo.coords.latitude; 
            postBody.longitude= geo.coords.longitude;
            console.log(`printing body ${JSON.stringify(postBody)}`)
            axios.post(`${process.env.REACT_APP_PROXY_PATH}/listing/search`, postBody, {
              headers: {
                "Content-Type": "application/json",
              }
            }).then((res) => {
              console.log(res.data)
              setIsLoading(false)
              setListings(res.data)
            }).catch(() => {
              setIsLoading(false)
              setListings([])
            })
          }, (err) => {
            console.log(err)
          });
         }
      }
      else{
        postBody.latitude =  geoLocation.latitude;
        postBody.longitude =  geoLocation.longitude;
      }
      axios.post(`${process.env.REACT_APP_PROXY_PATH}/listing/search`, postBody, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then((res) => {
        console.log(res.data)
        setIsLoading(false)
        setListings(res.data)
      }).catch(() => {
        setIsLoading(false)
        setListings([])
      })

    } else {
      notification.error({
        message: 'Invalid location',
        description:
          'Location is mandatory.'
      });
      setIsLoading(false)
    }
  }

  const area = searchKeys.height * searchKeys.breadth * searchKeys.length

  const handleRetrieve = useCallback((res) => {
    setGeoLocation({
      latitude: res.features[0].geometry.coordinates[1],
      longitude: res.features[0].geometry.coordinates[0]
    })
  }, []);

  const handleBuy = (listing_id) => {
    setShowSpin(true)
    axios.get(`${process.env.REACT_APP_PROXY_PATH}/listing?id=${listing_id}`).then((res) => {
      const userData = JSON.parse(localStorage.getItem('user'))
      console.log(res.data.owner);
      let listingUserId = res.data.owner;
      axios.get(`${process.env.REACT_APP_PROXY_PATH}/user?id=${listingUserId}`).then((resListing) => {
        let sellerAccountId = resListing.data.stripe_connect_id;
        axios.get(`${process.env.REACT_APP_PROXY_PATH}/listing/stripe?action=checkout&customerId=${userData.metadata.stripeUser.id}&connectedAccId=${sellerAccountId}&price=${res.data.metadata.stripePrice.id}`).then((res) => {
          window.open(res.data.checkoutURI, '_self')
        }).catch((err) => {
          console.log(err)
        })
      })
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    console.log("printing this after reneder");
    
    handleSearch("internal");
    axios.get(`${process.env.REACT_APP_PROXY_PATH}/user?id=${localStorage.getItem('token')}`).then((res) => {
      localStorage.setItem('user', JSON.stringify(res.data))
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  return (
    <div className='search-view'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-6 scroll-col'>
            <h3 className='text-skyblue mb-5'>Search Listing Near you</h3>
            <Form layout="vertical" size='large' onFinish={handleSearch}>
              <Form.Item name="searchText" label="Search Text" className='form-item'>
                <Input
                  placeholder="Text"
                  value={searchKeys.searchText}
                  onChange={handleChange}
                  name="searchText"
                />
              </Form.Item>

              <Form.Item name="units" label="Units" className='form-item' 
                  rules={[
                    {
                      required: true,
                      message: "Please select the units",
                    }
                  ]}
                  >
                <Radio.Group
                  onChange={(e) => {
                    setUnit(e.target.value)
                  }}
                  value={unit}
                >
                  <Space direction="vertical">
                    <Radio value='imperial'>m(Imperial)</Radio>
                    <Radio value='metric'>ft(Metric)</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <h4 className='text-skyblue'>Search by dimensions</h4>
              <Form.Item name="length" label={`Length ${unit === 'imperial' ? '(m)' : '(ft)'}`} className='form-item'
                  rules={[
                    {
                      required: true,
                      message: "Please add length",
                    }
                  ]}
              >
                <Input
                  name="length"
                  placeholder="Length"
                  value={searchKeys.length}
                  onChange={handleChange}
                  type='number'
                  min={0}
                />
              </Form.Item>

              <Form.Item name="breadth" label={`Breadth ${unit === 'imperial' ? '(m)' : '(ft)'}`} className='form-item'
                                rules={[
                                  {
                                    required: true,
                                    message: "Please add breadth",
                                  }
                                ]}
              >
                <Input
                  name="breadth"
                  placeholder="Breadth"
                  value={searchKeys.breadth}
                  onChange={handleChange}
                  type='number'
                  min={0}
                />
              </Form.Item>

              <Form.Item name="height" label={`Height ${unit === 'imperial' ? '(m)' : '(ft)'}`} className='form-item'
                                rules={[
                                  {
                                    required: true,
                                    message: "Please add height",
                                  }
                                ]}
              >
                <Input
                  name="height"
                  placeholder="Height"
                  value={searchKeys.height}
                  onChange={handleChange}
                  type='number'
                  min={0}
                />
              </Form.Item>

              <Form.Item name="searchDistance" label={`Distance ${unit === 'imperial' ? '(m)' : '(miles)'}`} className='form-item'
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Please add search distance/radius",
                                                }
                                              ]}>
                <Slider
                  min={5}
                  max={300}
                  defaultValue={5}
                  onChange={(val) => setSearchKeys({
                    ...searchKeys,
                    searchDistance: val
                  })}
                />
              </Form.Item>
              <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Select address to search from",
                    }
                  ]}
              >
      <h4 className='text-skyblue'>Search Address Selection</h4>
                <AddressAutofill
                  accessToken={process.env.REACT_APP_GOOGLE_MAP_ACCESS_TOKEN}
                  onRetrieve={handleRetrieve}
                >
                  <Input
                    type="text"
                    size="large"
                    id="address"
                    value={addressLine}
                    onChange={(e) => {
                      setAddressLine(e.target.value)
                    }}
                    autoComplete="shipping address-line1"
                    placeholder="Address to search from"
                  />
                </AddressAutofill>
              </Form.Item>
              <div className='mb-2'>
                <MapBox
                  geoLocation={geoLocation}
                  setGeoLocation={(val) => setGeoLocation(val)}
                />
              </div>

              <div className="totalArea border mt-0 mb-2 mx-0 w-50">
                <div className="areaText">Total Area : {area.toString() === 'NaN' ? '' : area} </div>
              </div>

              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Search
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className='col-12 col-lg-6'>
            <div className='row scroll-col'>
              <div className='col-12 d-flex justify-content-between'>
                <h3 className='text-skyblue mb-5'>Results Near you</h3>
                <div className='nearByResultsLimit'>
                  <Select
                    name="nearByResultsLimit"
                    size="large"
                    className="form-control"
                    value={searchKeys.nearByResultsLimit}
                    onChange={(val) => setSearchKeys({
                      ...searchKeys,
                      nearByResultsLimit: val
                    })}
                    placeholder=""
                  >
                    <Select.Option value={5}>5</Select.Option>
                    <Select.Option value={10}>10</Select.Option>
                    <Select.Option value={15}>15</Select.Option>
                    <Select.Option value={20}>20</Select.Option>
                    <Select.Option value={50}>50</Select.Option>
                    <Select.Option value={100}>100</Select.Option>
                  </Select>
                </div>
              </div>

              {
                isLoading && (
                  <div className='my-5 d-flex justify-content-center'>
                    <Spin size='large' />
                  </div>
                )
              }
              {
                !isLoading && listings.map((listing) => (
                  <div className='col-12 mb-3' key={listing.listing_id}>
                    <ListingCard
                      listing={listing}
                      handleView={() => {
                        setSelectedId(listing.listing_id)
                        setOpenDialog(true)
                      }}
                      handleBuy={() => {
                        setSelectedId(listing.listing_id)
                        setacceptTermDialog(true)
                      }}
                    />
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      {
        showSpin && (
          <div className="spin-wrapper">
            <Spin size='large' />
          </div>
        )
      }
      <ListingModal
        visible={openDialog}
        handleRequest={() => {
          setOpenDialog(false)
        }}
        handleCancel={() => {
          setOpenDialog(false)
        }}
        listing={listings.find((item) => item.listing_id === selectedId)}
      />
      <AcceptTermsModal
      visible={acceptTermDialog}
      listing={listings.find((item) => item.listing_id === selectedId)}
      handleRequest={() => {
        setacceptTermDialog(false)
        handleBuy(selectedId)
      }}
      handleCancel={() => {
        setacceptTermDialog(false)
      }}
      >
      </AcceptTermsModal>
    </div>
  )
}

export default Search
