import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Registration from "pages/Register";
import Login from "pages/Login";
import PasswordPut from "pages/PasswordPut";
import Layout from "./components/layout/Layout";
import PrivateRoutes from "./utils/privateRoute";

import AddListing from "pages/AddListing";
import EditListing from "pages/EditListing";
import ListingList from "pages/ListingList";
import LandingPage from "./components/landingpage";
import SearchPage from "pages/Search";
import SellerSignup from "pages/SellerSignup";
import StripeToken from "pages/StripeToken";
import MyPortal from "pages/MyPortal";
import Success from "pages/Success";
import Canceled from "pages/Canceled";
import Profile from "pages/Profile";
import terms from "./components/terms/terms";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import Search from "./components/Search/Search";
import ReactGA from 'react-ga4';




function App() {

const TRACKING_ID = "G-BB5FPPBREY"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

  return (
    <Layout>
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/terms" exact component={terms} />
        <Route path="/searchtest" exact component={Search} />
        <PrivateRoutes path="/home" exact component={Home} />
        <Route path="/register" component={Registration} />
        <Route path="/login" exact component={Login} />
        <Route path="/success" exact component={Success} />
        <Route path="/canceled" exact component={Canceled} />
        <Route path="/seller-signup" exact component={SellerSignup} />
        <Route path="/passwordPut" exact component={PasswordPut} />
        <PrivateRoutes path="/addlisting" exact component={AddListing} />
        <PrivateRoutes path="/editListing/:id" exact component={EditListing} />
        <PrivateRoutes path="/listing" exact component={ListingList} />
        <PrivateRoutes path="/search" exact component={SearchPage} />
        <PrivateRoutes path="/stripe/token" component={StripeToken} />
        <PrivateRoutes path="/portal" component={MyPortal} />
        <PrivateRoutes path="/profile" component={Profile} />
      </Switch>
    </Layout>
  );
}

export default App;
