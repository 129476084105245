import config from "../config";
import axios from "axios";

const registerUser = async (formData) => {
  const response  = await fetch(`${process.env.REACT_APP_PROXY_PATH}/user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...formData, // Use your own property name / key
    }),
  });

  console.log(response);
  // .then( async (res) => {
  //   const data = await res.json()
  //   console.log(data);
  //   return data;    
  // }).catch((errors) => {
  //   console.log(errors)
  // })  
 
};

const registerUserAxios = async (formData) => {
  let response = await axios.post(`${process.env.REACT_APP_PROXY_PATH}/user`, {
    ...formData
  });
return response;
}

const loginUser = (formData) => {
  return fetch(`${process.env.REACT_APP_PROXY_PATH}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...formData, // Use your own property name / key
    }),
  })
  .then( async (res, errors) => {
    return res;
    // const data = await res.json()
    // console.log("Login Response", data)
    // return data;
    // localStorage.setItem('token', res.token);
  })
  .catch((errors) => {
    console.log(errors)
  })
};

const passwordCreate = (formData) => {
  return fetch(`${process.env.REACT_APP_PROXY_PATH}/user`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...formData, // Use your own property name / key
    }),
  })
  .then( async (res, errors) => {
      return res;
    
    // localStorage.setItem('token', res.token);
  })
  .catch((errors) => {
    console.log(errors)
  })
};

export { registerUser, loginUser, passwordCreate , registerUserAxios};
