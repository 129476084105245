import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Spin } from 'antd';

import ListingCard from 'components/ListingCard'

import "assets/css/listing.css";

const ListingList = () => {

	const [listings, setListings] = useState([]);
	const [loading, setLoading] = useState(true);
	
	useEffect(() => {
		let comments = localStorage.getItem('token');
		const fetchPosts = async () => {
			try {
				const res = await fetch(`${process.env.REACT_APP_PROXY_PATH}/listing/list?owner_id=${comments}`);
				const postsData = await res.json();
				setListings(postsData)
			}
			catch (error) {
				console.log(error)
			}
			setLoading(false)
		};
		fetchPosts([])
	}, []);

	useEffect(() => {
		axios.get(`${process.env.REACT_APP_PROXY_PATH}/user?id=${localStorage.getItem('token')}`).then((res) => {
			localStorage.setItem('user', JSON.stringify(res.data))
		}).catch((err) => {
			console.log(err)
		})
	}, [])

	return (
		<>
			{
				loading ?
					<div className='position-absolute spin-load'>
						<Spin size='large' />
					</div>
					:
					<div className="marginTop">
						<div className='listing-body'>
							<div id="cards_landscape_wrap-2">
								<div className="container pt-5">
									<div className="row">
										{listings && !listings?.message?.includes("No listing found") ?
											listings.map((listing) => (
												<div key={listing.listing_id} className="col-xs-12 col-sm-6 col-md-3 col-lg-3 mb-3">
													<ListingCard
														listing={listing}
													/>
												</div>
											)) : <div style={{ color: "black" }}>"No Listings"</div>}
									</div>
								</div>
							</div>
						</div >
					</div>
			}		
		</>

	)
}
export default ListingList
