import React, { useEffect, useState } from 'react';
import { useHistory, useLocation} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import { Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { passwordCreate } from "apis/auth";

import "assets/css/auth.css";

const PasswordPut = () => {

  let history = useHistory();  
  let location = useLocation();
  const [formData, setFormData] = useState({userId: location.state})

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  };  

  let passwordMatch = '';
useEffect(() =>{
console.log(formData.userId);
},[]);

  const submitHandler = (event) => {
    event.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      passwordMatch = <div className="">Password does not match</div>
      toast.error(passwordMatch)
    } else {
      const newformData = {
        user_id: formData.userId,
        password: formData.password,
      };
      passwordCreate(newformData)
        .then((res) => {
          if (res.status == 200) {
            history.push("/login");
          }
        })
        .catch((error) => console.log(error));
    }
  }

  return (
    <div className="auth-bg">
      <div className="container" id="signUp-container">
        <div className="signUp-body">
          <div className="card bg-white">
            <div className="card-header bg-white">
              <h3 className='text-dark text-center mb-0'>Confirm Password</h3>
            </div>
            <div className="card-body">
              <form onSubmit={submitHandler}>
                <div className="input-group form-group">
                  <div className="d-flex justify-content-center">
                    <Input
                      type="text"
                      size='large'
                      prefix={<UserOutlined />}
                      name="userId"
                      placeholder="User_Id"
                      value={formData.userId}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="input-group form-group">
                  <div className="d-flex justify-content-center">
                    <Input
                      type="password"
                      size='large'
                      prefix={<LockOutlined />}
                      name="password"
                      placeholder="Password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="input-group form-group">
                  <div className="d-flex justify-content-center">
                    <Input
                      type="password"
                      size='large'
                      prefix={<LockOutlined />}
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group-btn">
                  <Button type='primary' onClick={submitHandler} className='w-100 border-0 bg-skyblue'>
                    Create
                  </Button>
                </div>
                <div className="d-flex justify-content-center links text-dark">
                  Do have an account?<a href="/login" id="signIn-btn" className='text-skyblue'>Sign In</a>
                </div>
              </form>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default PasswordPut;
