import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Button, Skeleton } from "antd";

const ListingCard = (props) => {
  const { listing, handleView, handleBuy } = props;

  const [imgData, setImgData] = useState();

  useEffect(() => {
    if (listing.metadata.images) {
      if (listing.metadata.images.length > 0) {
        axios
          .get(
            `${process.env.REACT_APP_PROXY_PATH}/files?fileName=${listing.metadata.images[0]}`
          )
          .then((res) => {
            setImgData(`data:image/jpeg;base64, ${res.data}`);
          })
          .catch(() => {});
      }
    }
  }, []);

  return (
    <Card className="listing-h-card">
      <div className="w-50">
        {imgData ? (
          <img src={imgData} alt="" height={200} className="w-100" />
        ) : (
          <Skeleton.Image className="listing-skeleton-img" />
        )}
      </div>

      <div className="w-50 card-details px-3">
        <h6 className="text-bold">{listing.listing_name}</h6>
        <div className="listing-detail-line d-flex">
          <span>Listing Address : </span>
          <span className="text-end">{listing.listing_address}</span>
        </div>
        <div className="listing-detail-line d-flex">
          <span>Max Height : </span>
          <span className="text-end">{listing.max_height}</span>
        </div>
        <div className="listing-detail-line d-flex">
          <span>Space Area ; </span>
          <span className="text-end">{listing.space_area}</span>
        </div>
        <div className="listing-detail-line d-flex">
          <span>Owner : </span>
          <span className="text-end">{listing.owner}</span>
        </div>
        <div className="mt-3 text-center">
          <Button size="large" onClick={handleView}>
            View
          </Button>
          <Button size="large" onClick={handleBuy} className="ms-3">
            Buy
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ListingCard;
