import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Button, Avatar, Table } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import 'assets/css/portal.css'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => (
    <div className='d-flex align-items-center'>
      <Avatar size={40} icon={<UserOutlined />} className='d-flex align-items-center justify-content-center' />
      <span className='ms-3 fw-bold'>{text}</span>
    </div>
    ),
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (text) => `$${text}`
  } 
];

const data = [
  {
    key: '1',
    name: 'John Brown',
    date: 'Last Thursday at 2:12 AM',
    price: 40.36 
  },
  {
    key: '2',
    name: 'Jim Green',
    date: 'Last Thursday at 2:11 AM',
    price: 25.50
  },
  {
    key: '3',
    name: 'Joe Black',
    date: 'Last Thursday at 2:11 AM',
    price: 51.37
  },
];

const MyPortal = () => {

  const [formData, setFormData] = useState({});
  const [userData, setUserData] = useState({});
	const [loading, setLoading] = useState(true);


  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_PROXY_PATH}/user?id=${localStorage.getItem(
          "token"
        )}`
      )
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data));
        setUserData(res.data);
      })
      .catch((err) => {
        console.log(`Error fetching the profile ${err}`);
      });
    let payload = {
      action: "profile",
      userId: `${localStorage.getItem("token")}`,
    };
    axios
      .post(`${process.env.REACT_APP_PROXY_PATH}/listing/stripe`, payload)
      .then((res) => {
        setFormData(res.data);
        setLoading(false)
      })
      .catch((err) => {
        console.log(`Error fetching the user profile ${err}`);
      });
  },[]);

  return (
    <>
    {
      loading ?
      <div className='position-absolute spin-load'>
      <Spin size='large' />
    </div>
    :    
    <div className="portal-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-6 col-md-5">
            <div className="d-flex mb-3">
              <Avatar size={64} icon={<UserOutlined />} className='d-flex align-items-center justify-content-center' />
              <div className='user-info ms-3'>
                <p className='mb-0'>Seller Portal</p>
                <h3>{userData.first_name.toUpperCase() + " " + userData.last_name.toUpperCase()}</h3>
                <a href="/">View Stripe account</a>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-2">
            <div className="user-info">
              <p className='mb-0'>This week</p>
              <h3>${formData.balancePending}</h3>
            </div>
          </div>
          <div className="col-6 col-md-2">
            <div className="user-info mb-3">
              <p className='mb-0'>Your balance</p>
              <h3>{formData.balanceCurrency == "cad" ? "CA" : "US"} ${formData.balanceAvailable}</h3>
              <span className='fw-bold'>{formData.balanceCurrency == "cad" ? "CA" : "US"} ${formData.balanceAvailable} available</span>
            </div>
          </div>
          <div className="col-6 col-md-3 user-info mb-3">
            <Button type="primary" size='large' className='d-block mb-3'>Pay out now</Button>
            <a href={formData.expressLoginLink}>View payouts on Stripe</a>
          </div>

          {/* <div className="col-12">
            <h5 className='mt-5 mb-4'>
              Recent rides
            </h5>
            <Table columns={columns} dataSource={data} showHeader={false} />
          </div> */}
        </div>
      </div>
    </div>  
    }
    </>
  )
}

export default MyPortal
