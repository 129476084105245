import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { Card, Skeleton } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import "assets/css/listing.css";

const ListingCard = (props) => {

  const { listing } = props

  const history = useHistory()

  const [imgData, setImgData] = useState()

  useEffect(() => {

    if (listing.metadata.images) {
      if (listing.metadata.images.length > 0) {

        const fileName = listing.metadata.images[0]

        axios.get(`${process.env.REACT_APP_PROXY_PATH}/files?fileName=${fileName}`).then((res) => {
          setImgData(`data:image/jpeg;base64, ${res.data}`)
        }).catch((err) => {
          console.log(err)
        })
      }
    }
  }, [])

  return (
    <Card
      hoverable
      cover={
        imgData ? <img alt="example" src={imgData} height={200} /> : <Skeleton.Image className='listing-skeleton-img' />
      }
      actions={[
        <EditOutlined key="edit" onClick={() => history.push(`/editListing/${listing.listing_id}`)} />      
     ]}
    >
      <div className="text-container text-start">
        <h6 className='text-center list-title text-dark'>
          {listing.listing_name}
        </h6>
        <div className='listing-detail-line d-flex'>
          <span>Listing Address : </span><span className='text-end'>{listing.listing_address}</span>
        </div>
        <div className='listing-detail-line d-flex'>
          <span>Max Height : </span><span className='text-end'>{listing.max_height}</span>
        </div>
        <div className='listing-detail-line d-flex'>
          <span>Space Area ; </span><span className='text-end'>{listing.space_area}</span>
        </div>
        <div className='listing-detail-line d-flex'>
          <span>Owner : </span><span className='text-end'>{listing.owner}</span>
        </div>
      </div>
    </Card>
  )
}

export default ListingCard
