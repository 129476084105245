import { Layout } from 'antd';
import Header from "./Header/headerNav";
import Footer from "./footer";
import { Content } from 'antd/lib/layout/layout';

import classes from './Layout.module.css'

function LayoutBox(props) {
  return (
    <Layout>
      <Header />
      <Content className={classes.contents}>{props.children}</Content>
      <Footer />      
    </Layout>
  );
}

export default LayoutBox;
