import React, { forwardRef } from "react";
import ReactMapboxGl from "react-mapbox-gl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';


    // had this.


    // added the following 6 lines.

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1Ijoicm1pcmFsaSIsImEiOiJjbDQ2ZG9qbXgwN21sM2JwaWlsdjltZnh5In0.VWKdJjibO1ZEg92BOTz2Gg"
});

export const defaultLocation = {
  latitude: -24.68537394903315,
  longitude: 133.17646084178398
}

const MapBox = forwardRef((props, ref) => { 

  const { geoLocation, setGeoLocation} = props 

  const onStyleLoaded = (map) => {
    map.setZoom(12);
  }

  const handleClick = (e, val) => {   
    setGeoLocation({
      latitude: val.lngLat.lat,
      longitude: val.lngLat.lng
    })
  }
  
  const onMoveEnd = (map) => {
    if (map.getCenter().lat !== defaultLocation.latitude || map.getCenter().lng !== defaultLocation.longitude) {
      setGeoLocation({
        latitude: map.getCenter().lat,
        longitude: map.getCenter().lng
      })
    }
  }

  return (
    <div className="map-relative">
      <Map
        style="mapbox://styles/mapbox/streets-v9" // eslint-disable-line
        onStyleLoad={onStyleLoaded}
        onClick={handleClick}
        onMoveEnd={onMoveEnd}
        containerStyle={{
          height: `300px`,
          width: `100%`
        }}
        center={ !!geoLocation ? [geoLocation.longitude, geoLocation.latitude] : [defaultLocation.longitude, defaultLocation.latitude]}
      >
      </Map>
    </div>
  );
})

export default MapBox;
