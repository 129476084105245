import React from 'react';
import PropTypes from 'prop-types';
import styles from './Search.module.less';
import Button from 'antd/es/button';


const Search = () => (
  <div className="container" data-testid="Search">
    Search Component
    <Button type='primary'>First Button</Button>
    <div>Hello world</div>
  </div>
);

Search.propTypes = {};
Search.defaultProps = {};
export default Search;
