import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { Menu, Dropdown } from 'antd';

import NavMenuDraw from './navMenuDraw';
import SellerPortal from './SellerPortal'

import classes from './headerNav.module.css';

const HeaderNav = () => {
  const history = useHistory();
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const [currentUser, setCurrentUser] = useState(undefined);
  useEffect(() => {
    setInterval(() => {
      const userString = localStorage.getItem('token');
      setCurrentUser(userString);
    }, [])
  }, 5000);

  const logout = () => {
    history.push("/");
    return localStorage.removeItem("token");
  }

  return (
    <>
      <div className={scroll ? classes.headerToolScroll : classes.headerTool}>
        <Menu mode="horizontal" className={`w-100 justify-content-end border-0 ${classes.navMenu}`}>
          {currentUser ? (
            <>
              <Menu.Item onClick={() => history.push('/')} className={classes.navLink} key="home">
                Home
              </Menu.Item>
              <Menu.Item onClick={() => history.push('/search')} className={classes.navLink} key="search">
                Search
              </Menu.Item>

              <Menu.Item className={classes.navLink}>
                <Dropdown 
                  overlay={<SellerPortal/>} 
                  placement="topRight" 
                  arrow
                >
                  <Menu.Item className={classes.navLink + ' ' + classes.sellerPortal}>Seller Portal</Menu.Item>
                </Dropdown>
              </Menu.Item>

              <Menu.Item onClick={() => history.push('/profile')} className={classes.navLink} key="Profile">
                Profile
              </Menu.Item>

              <Menu.Item onClick={logout} className={classes.navLink} key="logout">
                Logout
              </Menu.Item>
            </>
          ) : (
            <>
              <Menu.Item onClick={() => history.push('/')} className={classes.navLink} key="home">
                Home
              </Menu.Item>
              <Menu.Item onClick={() => history.push('/login')} className={classes.navLink} key="login">
                Login
              </Menu.Item>
              <Menu.Item onClick={() => history.push('/register')} className={classes.navLink} key="register">
                Sign Up
              </Menu.Item>
              <Menu.Item onClick={() => history.push('/passwordPut')} className={classes.navLink} key="passowrd">
                Change Password
              </Menu.Item>
              <Menu.Item onClick={() => history.push('/')} className={classes.navLink} key="contact">
                Contact Us
              </Menu.Item>
            </>
          )}
        </Menu>
      </div>
      <div className={scroll ? classes.headerToolResponsiveScroll : classes.headerToolResponsive}>
        <div className={classes.navbarLogo}>
          <img alt='' className={classes.navbarLogoImg} src='/images/logo.png' />
        </div>
        <NavMenuDraw />
      </div>
    </>
  );
}
export default HeaderNav
