import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';

import { Button, Modal, Input, Skeleton, Form, Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import './styles.css'

const ListingModal = (props) => {

  const carouselRef = useRef()

  const { visible, handleRequest, handleCancel, listing } = props

  const [images, setImages] = useState([])

  useEffect(async () => {
    if (!!listing?.metadata) {

      let imageList = []

      for (var i = 0; i < listing.metadata.images.length; i++) {

        await axios.get(`${process.env.REACT_APP_PROXY_PATH}/files?fileName=${listing.metadata.images[i]}`).then((res) => {
          imageList.push({
            uid: listing.metadata.images[i].split('.')[0],
            name: listing.metadata.images[i],
            fileName: listing.metadata.images[i],
            status: "done",
            preview: `data:image/jpeg;base64, ${res.data}`,
            url: `data:image/jpeg;base64, ${res.data}`
          })
        }).catch(() => {
        })
      }
      setImages(imageList)
    }
  }, [listing])

  console.log(images)

  return (
    <Modal
      visible={visible}
      title={listing?.listing_name}
      onCancel={handleCancel}
    >
      {
        !!listing ? (
          <div>
            <Form layout="vertical" size='large'>
              <Form.Item name="address" label="Address" className='form-item' initialValue={listing?.listing_address ?? ""}>
                <Input
                  readOnly
                />
              </Form.Item>

              <Form.Item name="description" label="Description" className='form-item' initialValue={listing?.metadata.description}>
                <Input.TextArea
                  readOnly
                />
              </Form.Item>

              <Form.Item name="units" label="Units" className='form-item' initialValue={listing.metrics}>
                <Input
                  readOnly
                />
              </Form.Item>

              <Form.Item name="length" label="Length" className='form-item' initialValue={listing?.metadata.length}>
                <Input
                  readOnly
                />
              </Form.Item>

              <Form.Item name="breadth" label="Breadth" className='form-item' initialValue={listing?.metadata.breadth}>
                <Input
                  readOnly
                />
              </Form.Item>

              <Form.Item name="height" label="Height" className='form-item' initialValue={listing?.metadata.height}>
                <Input
                  readOnly
                />
              </Form.Item>

              <Form.Item name="max_height" label="Max height" className='form-item' initialValue={listing?.max_height}>
                <Input
                  readOnly
                />
              </Form.Item>

              <div className='carousel-wrapper'>
                {
                  images.length > 0 && (
                    <>
                      <Button 
                        type="primary" 
                        shape="circle" 
                        icon={<LeftOutlined />} 
                        className='carousel-prev-btn' 
                        onClick={() => carouselRef.current.prev()}
                      />
                      <Button 
                        type="primary" 
                        shape="circle" 
                        icon={<RightOutlined />} 
                        className='carousel-next-btn' 
                        onClick={() => carouselRef.current.next()}
                      />
                    </>
                  )
                }

                <Carousel ref={carouselRef} dots={false}>
                  {
                    images.map((img) => (
                      <div key={img.uid}>
                        <img src={img.preview} alt="" className='carousel-img' />
                      </div>
                    ))
                  }
                </Carousel>
              </div>
            </Form>
          </div>
        ) : (
          <Skeleton size='large' className='marginTop' />
        )
      }
    </Modal>
  )
}

export default ListingModal
