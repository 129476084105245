import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Input, Select, Button, Skeleton } from 'antd';
import { AddressAutofill } from '@mapbox/search-js-react';

import MapBox from 'components/MapBox'
import ImagesUpload from 'components/ImagesUpload'

import "assets/css/listing.css";

const EditListing = (props) => {

  const { TextArea } = Input;
  const { Option } = Select;
  
  const [addressLine, setAddressLine] = useState("")

  const [state, setState] = useState({});
  const [isLoaded, setIsLoaded] = useState(false)
  const [image, setFile] = useState([]);
  const [geoLocation, setGeoLocation] = useState()

  let history = useHistory();

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoaded(false)
      const res = await fetch(`${process.env.REACT_APP_PROXY_PATH}/listing?id=${props.match.params.id}`);
      const postsData = await res.json();
    
      let imageList = []
      if (!!postsData.metadata.images) {
        for (var i = 0; i < postsData.metadata.images.length; i++) {

          await axios.get(`${process.env.REACT_APP_PROXY_PATH}/files?fileName=${postsData.metadata.images[i]}`).then((res) => {
            imageList.push({
              uid: postsData.metadata.images[i].split('.')[0],
              name: postsData.metadata.images[i],
              fileName: postsData.metadata.images[i],
              status: "done",
              preview: `data:image/jpeg;base64, ${res.data}`,
              url: `data:image/jpeg;base64, ${res.data}`
            })
          }).catch(() => {

          })
        }
        setFile(imageList)
      }

      setGeoLocation({
        latitude: postsData.latitude,
        longitude: postsData.longitude,
      })

      setState({
        listing_name: postsData.listing_name,
        metadata: postsData.metadata,
        description: postsData.metadata.description,
        active: true,
        metrics: postsData.metrics,
        owner: postsData.owner,
        max_height: postsData.max_height,
        space_area: postsData.space_area,
        height: postsData.metadata.height,
        breadth: postsData.metadata.breadth,
        length: postsData.metadata.length,
        price: postsData.price
      })
      setAddressLine(postsData.listing_address)
      setIsLoaded(true)
    };
    fetchPosts();
  }, []);

  const handleMetricsChange = (value) => {
    setState({
      ...state,
      metrics: value
    });
  }

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const areaeidt = state.height * state.length * state.breadth;
  const listingId = props.match.params.id

  const submitHandler = async (event) => {
    event.preventDefault();
    const formData = {
      listing_id: listingId,
      listing_name: state.listing_name,
      listing_address: addressLine,
      active: true,
      latitude: geoLocation.latitude,
      longitude: geoLocation.longitude,
      metrics: state.metrics,
      owner: state.owner,
      max_height: state.max_height,
      space_area: areaeidt,
      price: state.price
    };
    formData.metadata = {
      description: state.description,
      images: image.map((img) => img.fileName),
      length: state.length,
      breadth: state.breadth,
      height: state.height
    };

    axios.put(`${process.env.REACT_APP_PROXY_PATH}/listing`, formData).then((res) => {
      console.log(res)
      history.push("/listing");
    }).catch((err) => {
      console.log(err)
    }) 
  }

  const handleRetrieve = useCallback((res) => {
    setGeoLocation({
      latitude: res.features[0].geometry.coordinates[1],
      longitude: res.features[0].geometry.coordinates[0]
    })
  }, []);

  return (
    <>
      {
        !isLoaded ? <Skeleton size='large' className='marginTop' /> : (
          <div className="listing-bg bg-white">
            <div className="listing-title">
              Edit Listing
            </div>
            <form onSubmit={submitHandler}>
              <div className="d-flex flex-row">
                <div className="d-flex flex-column" style={{ width: "65%" }}>
                  <Input
                    type="text"
                    size="large"
                    style={{ marginBottom: 10 }}
                    className="form-control"
                    id="listing_name"
                    placeholder="Listing Name"
                    value={state.listing_name}
                    name="listing_name"
                    onChange={handleChange}
                  />                
                  <AddressAutofill
                    accessToken={process.env.REACT_APP_GOOGLE_MAP_ACCESS_TOKEN}
                    onRetrieve={handleRetrieve}
                  >
                    <Input
                      type="text"
                      size="large"
                      id="address"
                      value={addressLine}
                      onChange={(e) => {
                        setAddressLine(e.target.value)
                      }}
                      autoComplete="shipping address-line1"
                      placeholder="Listing Address(populate the map to the left)"
                    />
                  </AddressAutofill>
                  <TextArea
                    className="form-control"
                    id="description"
                    placeholder="Description"
                    cols="10" rows="8"
                    value={state.description}
                    name="description"
                    onChange={handleChange}
                    style={{marginTop: '10px'}}
                  />
                  <div className="mb-10-px">
                    <div className="field-title">Price Monthly</div>
                    <Input
                      type="number"
                      size="large"
                      className="form-control"
                      id="price"
                      placeholder="Price"
                      value={state.price}
                      name="price"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="map-box ml-2">
                  <MapBox
                    geoLocation={geoLocation}
                    setGeoLocation={(val) => setGeoLocation(val)}
                  />
                </div>
              </div>
              <div className="metrics-select mb-10-px">
                <label className="cars" htmlFor="cars">Calculate Area In</label>
                <Select
                  name="metrics"
                  size="large"
                  className="form-control"
                  value={state.metrics}
                  onChange={handleMetricsChange}
                >
                  <Option value="imperial">m(Imperial)</Option>
                  <Option value="metric">ft(Metric)</Option>
                </Select>
              </div>
              <div className="d-flex flex-row" style={{ margintop: 20 }}>
                <div className="d-flex flex-column" style={{ width: "55%" }}>
                  <div className="mb-10-px">
                    <div className="field-title">Enter Lenth</div>
                    <Input
                      type="text"
                      size="large"
                      className="form-control"
                      id="lenth"
                      placeholder="lenth"
                      value={state.length}
                      name="length"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-10-px">
                    <div className="field-title">Enter Breadth</div>
                    <Input
                      type="text"
                      size="large"
                      className="form-control"
                      id="breadth"
                      placeholder="Breadth"
                      value={state.breadth}
                      name="breadth"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-10-px">
                    <div className="field-title">Enter Height</div>
                    <Input
                      type="text"
                      size="large"
                      className="form-control"
                      id="height"
                      placeholder="Height"
                      value={state.height}
                      name="height"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-10-px">
                    <div className="field-title">Max Ceiling Height</div>
                    <Input
                      type="text"
                      size="large"
                      className="form-control"
                      id="ceilingHeight"
                      placeholder="Max Ceiling Height"
                      value={state.max_height}
                      name="max_height"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-10-px">
                    <div className="field-title">Owner</div>
                    <Input
                      type="text"
                      size="large"
                      className="form-control"
                      id="owner"
                      placeholder="Owner"
                      value={state.owner}
                      name="owner"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="totalArea border">
                  <div className="areaText">Total Area : {areaeidt} </div>
                </div>
              </div>
              <div className="d-flex flex-column" style={{ margintop: 20 }}>
                <p className="field-title">Upload Listing Imags : </p>
                <ImagesUpload
                  setFileList={(fileList) => setFile(fileList)}
                  fileList={image}
                />
              </div>
              <div className="form-group-btn listing-craete-btn">
                <Button className="float-right" size='large' shape="round" onClick={submitHandler}>
                  Update
                </Button>
                <Button type='primary' size='large' onClick={() => history.goBack()} shape="round" className='ml-2'>
                  Back
                </Button>
              </div>
            </form>
          </div>
        )
      }
    </>
  );
};

export default EditListing;
