import React from 'react'

import { useHistory } from 'react-router-dom'

import { Menu } from 'antd';

import classes from './headerNav.module.css';

const SellerPortal = (props) => {  

  const {} = props

  const history = useHistory()
  let checkIsSeller = ()=>
  {
    let boolValue = JSON.parse(localStorage.user).is_seller;
    console.log(boolValue);
    return boolValue;
  } 

  return (
    <Menu className={classes.sellerPortalMenu}>
      <Menu.Item onClick={() => history.push('/seller-signup')}>
        Seller Registration
      </Menu.Item>
      <Menu.Item disabled={!checkIsSeller()} onClick={() => history.push('/addlisting')}>
      Create Listing
      </Menu.Item>
      <Menu.Item disabled={!checkIsSeller()} onClick={() => history.push('/portal')}>
        My Portal
      </Menu.Item>
      <Menu.Item disabled={!checkIsSeller()} onClick={() => history.push('/listing')}>
        My Listing
      </Menu.Item>
      <Menu.Item disabled={!checkIsSeller()} onClick={() => history.push('/request')}>
        Customer Request
      </Menu.Item>          
    </Menu>
  )
}

export default SellerPortal
